<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab1"
              href="#"
              role="tab1"
            >
              Dados Fiscais
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab3"
              href="#"
              role="tab1"
            >
              Dados GLP
            </a>
          </li>
          <li class="nav-item" v-if="isComposto">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab4"
              href="#"
              role="tab1"
            >
              Composição
            </a>
          </li>
          <li class="nav-item" v-if="isVariavel">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="4"
              data-toggle="tab5"
              href="#"
              role="tab1"
            >
              Variação
            </a>
          </li>
          <li class="nav-item" v-if="isPerca">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="5"
              data-toggle="tab6"
              href="#"
              role="tab1"
            >
              Decompor
            </a>
          </li>
        </ul>
        <div
          :style="{
            width: '120px',
            height: '120px',
            cursor: 'pointer',
            marginRight: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            fontSize: '0.8rem',
            border: !urlProdutoImage ? '1px dashed rgb(207, 207, 207)' : '',
            color: 'rgb(207, 207, 207)',
          }"
          @click="selectFiles"
        >
          <b-card-img
            v-if="urlProdutoImage"
            :src="urlProdutoImage"
            class="rounded-0"
            style="
              object-fit: cover;
              width: 120px;
              height: 120px;
              object-position: center;
            "
          />
          <p v-else class="mb-0">Insira uma foto</p>
        </div>
      </div>

      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="2">
                <b-form-group id="codigo" label-for="codigo" label="Código">
                  <b-form-input v-model="form.codigo" disabled> </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="descricao"
                  label-for="descricao"
                  label="Descrição"
                >
                  <b-form-input
                    maxlength="100"
                    v-model="$v.form.descricao.$model"
                    :state="validateState('descricao')"
                    autofocus
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="marca" label-for="marca" label="Marca">
                  <b-form-input maxlength="100" v-model="form.marca" autofocus>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group id="custo" label-for="custo" label="Custo">
                  <money
                    class="form-control"
                    v-model="form.custo"
                    v-bind="priceOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="markup" label-for="markup" label="Markup %">
                  <money
                    class="form-control"
                    v-model="form.markup"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="quantidade"
                  label-for="quantidade"
                  label="Quantidade"
                >
                  <money
                    class="form-control"
                    v-model="form.quantidade"
                    v-bind="quantityOptions"
                    :disabled="isVariavel"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="preco" label-for="preco" label="Valor">
                  <money
                    class="form-control"
                    v-model="form.preco"
                    v-bind="priceOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="preco_atacado"
                  label-for="preco_atacado"
                  label="Valor Atacado"
                >
                  <money
                    class="form-control"
                    v-model="form.preco_atacado"
                    v-bind="priceOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="quantidade_atacado"
                  label-for="quantidade_atacado"
                  label="Quantidade Atacado"
                >
                  <money
                    class="form-control"
                    v-model="form.quantidade_atacado"
                    v-bind="quantityOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="referencia"
                  label-for="referencia"
                  label="Referência"
                >
                  <b-form-input v-model="form.referencia" maxlength="50">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Unidade de Medida"
                  label-for="unidade_medida"
                >
                  <select
                    name="unidade_medida"
                    id="unidade_medida"
                    v-model="form.id_medida"
                    class="form-control"
                  >
                    <option
                      v-for="(u, index) in unidadeMedida"
                      :value="u.id"
                      :key="'unidade_medida' + index"
                    >
                      {{ u.nome }}
                    </option>
                  </select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="grupo" label="Grupo" label-for="grupo">
                  <VueSelect
                    placeholder="Digite o nome para pesquisar"
                    label="grupo"
                    :filterable="false"
                    @input="setGrupo"
                    :options="grupos"
                    @search="onSearchGrupo"
                    v-model="grupo"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o nome para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="cod_barras"
                  label-for="cod_barras"
                  label="Código de Barras"
                >
                  <b-form-input v-model="form.cod_barras"> </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="cod_barras"
                  label-for="cod_barras"
                  style="margin-top: 25px"
                >
                  <button
                    @click="searchCodigoBarra(form.cod_barras)"
                    class="btn btn-md btn-primary btn-hover-primary btn-icon mr-2"
                    v-b-tooltip.hover.bottom="'Consultar via código de barras'"
                  >
                    <SearchWeb :size="20" />
                  </button>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="data_ultima_venda"
                  label-for="data_ultima_venda"
                  label="Data Últ. Venda"
                >
                  <b-form-input
                    :disabled="!form.id_produto"
                    v-model="form.data_ultima_venda"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="data_ultima_compra"
                  label-for="data_ultima_compra"
                  label="Data Últ. Compra"
                >
                  <b-form-input
                    :disabled="!form.id_produto"
                    v-model="form.data_ultima_compra"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="numero_ultima_compra"
                  label-for="numero_ultima_compra"
                  label="Número Últ. Compra"
                >
                  <b-form-input
                    v-model="form.numero_ultima_compra"
                    maxlength="20"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="fornecedor"
                  label="Fornecedor Preferencial"
                  label-for="fornecedor"
                >
                  <VueSelect
                    placeholder="Digite o nome para pesquisar"
                    label="fornecedor"
                    :filterable="false"
                    @input="setFornecedor"
                    :options="fornecedores"
                    @search="onSearchFornecedor"
                    v-model="fornecedor"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o nome para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo + " - " + option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="peso" label-for="peso" label="Peso">
                  <money
                    class="form-control"
                    v-model="form.peso"
                    v-bind="quantityOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="estoque_minimo"
                  label-for="estoque_minimo"
                  label="Estoque Mínimo"
                >
                  <money
                    class="form-control"
                    v-model="form.estoque_minimo"
                    v-bind="quantityOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group id="tipo" label="Tipo" label-for="tipo">
                  <b-form-select v-model="form.id_tipo" placeholder="">
                    <b-form-select-option
                      v-for="tipo in tipos"
                      :key="tipo.id + 'plano_contas'"
                      :value="tipo.id"
                    >
                      {{ tipo.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group id="origem" label="Origem" label-for="origem">
                  <b-form-select v-model="form.id_origem" placeholder="">
                    <b-form-select-option
                      v-for="origem in origens"
                      :key="origem.id + 'origem'"
                      :value="origem.id"
                    >
                      {{ origem.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group
                  id="descricao"
                  label-for="desc_complementar"
                  label="Descrição Complementar"
                >
                  <b-form-input
                    v-model="form.desc_complementar"
                    maxlength="250"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <input
                  id="fileUpload"
                  type="file"
                  hidden
                  accept=".jpg, .png"
                  @change="setProdutoImage"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="obs" label="Observação" label-for="obs">
                  <b-form-textarea
                    id="obs"
                    name="obs"
                    v-model="form.obs"
                    placeholder=""
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="1"
                ><br />
                <b-form-group id="ativo" label-for="ativo" label="Ativo">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setAtivo"
                        :checked="form.ativo == true"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="1"
                ><br />
                <b-form-group
                  id="composto"
                  label-for="composto"
                  label="Composto"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        @change="setComposto"
                        :checked="isComposto"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="1"
                ><br />
                <b-form-group
                  id="variacao"
                  label-for="variacao"
                  label="Variação"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input type="checkbox" v-model="isVariavel" />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="1"
                ><br />
                <b-form-group id="perca" label-for="perca" label="Decompor">
                  <span class="switch switch-icon">
                    <label>
                      <input type="checkbox" v-model="isPerca" />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <button
              v-b-modal.modal-assistant
              class="btn btn-primary font-weight-bold"
            >
              Assistente para cálculo do preço de venda
            </button>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="CST/CSOSN não contribuinte"
                  label-for="form.id_cst_csosn"
                >
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    label="codigo"
                    @input="setCSTCSOSN"
                    v-model="cstcsosn"
                    :options="cstcsosns"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="CST/CSOSN contribuinte"
                  label-for="form.id_cst_csosn_contribuinte"
                >
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    label="codigo"
                    @input="setCSTCSOSNContribuinte"
                    v-model="cstcsosnContribuinte"
                    :options="cstcsosns"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código para buscar
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="CFOP NFe" label-for="form.id_cfop">
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    ref="buscarCliente"
                    :filterable="false"
                    v-model="cfop"
                    @input="setCFOP"
                    :options="cfops"
                    @search="onSearchCFOP"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CFOP
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="CFOP NFce" label-for="form.id_cfop_nfce">
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    ref="buscarCliente"
                    :filterable="false"
                    v-model="cfop_nfce"
                    @input="setCFOPNFce"
                    :options="cfops_nfce"
                    @search="onSearchCFOPNfce"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CFOP
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="CEST" label-for="form.id_cest">
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    label="codigo"
                    @input="setCEST"
                    v-model="cest"
                    :options="cests"
                    @search="onSearchCEST"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CEST
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{
                          option.codigo + " - " + option.descricao
                            ? option.codigo +
                              " - " +
                              option.descricao.substring(0, 55) +
                              "..."
                            : ""
                        }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="NCM" label-for="form.id_ncm">
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    label="codigo"
                    @input="setNCM"
                    v-model="ncm"
                    :options="ncms"
                    @search="onSearchNCM"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do NCM
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="excecaoFiscal"
                  label-for="excecaoFiscal"
                  label="Exceção Fiscal"
                >
                  <b-form-input v-model="form.excecaoFiscal"> </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="CENQ" label-for="form.id_cenq">
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    label="codigo"
                    @input="setCENQ"
                    v-model="cenq"
                    :options="cenqs"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CENQ
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="CST IPI" label-for="form.id_cstipi">
                  <VueSelect
                    placeholder="Digite o código para pesquisar"
                    label="codigo"
                    @input="setCSTIPI"
                    v-model="cstipi"
                    :options="cstipis"
                    @search="onSearchCSTIPI"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CST IPI
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="CST PIS" label-for="form.id_cstpis">
                  <VueSelect
                    label="codigo"
                    @input="setCSTPIS"
                    v-model="cstpis"
                    :options="cstspis"
                    placeholder="Digite o código para pesquisar"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CST PIS
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="CST COFINS" label-for="form.id_cstcofins">
                  <VueSelect
                    label="codigo"
                    v-model="cstcofins"
                    :options="cstscofins"
                    placeholder="Digite o código para pesquisar"
                    :clearable="true"
                  >
                    <template slot="no-options">
                      Informe o código do CST Cofins
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo + " - " + option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="tributacoes.length > 0">
                <b-form-group label="Tributação" label-for="form.id_tributacao">
                  <VueSelect
                    label="descricao"
                    @input="setTributacao"
                    v-model="tributacao"
                    :options="tributacoes"
                    :clearable="true"
                  >
                    <template slot="no-options"> Informe a descricao </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{
                          option.descricao
                            ? option.descricao.substring(0, 20)
                            : ""
                        }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="tributacoes.length > 0">
                <b-form-group
                  label="Tributação ST"
                  label-for="form.id_tributacao_st"
                >
                  <VueSelect
                    label="descricao"
                    @input="setTributacaoST"
                    v-model="tributacaoST"
                    :options="tributacoes"
                    :clearable="true"
                  >
                    <template slot="no-options"> Informe a descricao </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{
                          option.descricao
                            ? option.descricao.substring(0, 20)
                            : ""
                        }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="tributacoes.length > 0">
                <b-form-group label="FCP" label-for="form.id_fcp">
                  <VueSelect
                    label="fcp"
                    @input="setFCP"
                    v-model="fcp"
                    :options="tributacoes"
                    :clearable="true"
                  >
                    <template slot="no-options"> Informe a descricao </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{
                          option.descricao
                            ? option.descricao.substring(0, 20)
                            : ""
                        }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group id="ipi" label-for="ipi" label="% IPI">
                  <money
                    class="form-control"
                    v-model="form.ipi"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="pist" label-for="pis" label="% PIS">
                  <money
                    class="form-control"
                    v-model="form.pis"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="cofins" label-for="cofins" label="% COFINS">
                  <money
                    class="form-control"
                    v-model="form.cofins"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="mva" label-for="mva" label="% MVA">
                  <money
                    class="form-control"
                    v-model="form.mva"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="codigo_beneficio"
                  label-for="codigo_beneficio"
                  label="Código Benefício"
                >
                  <b-form-input v-model="form.codigo_beneficio" maxlength="20">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col md="3">
                <b-form-group label="ANP" label-for="form.id_anp">
                  <VueSelect
                    label="id_anp"
                    @input="setANP"
                    v-model="anp"
                    :options="anps"
                    placeholder="Digite o código para pesquisar"
                    @search="onSearchANP"
                    :filterable="false"
                  >
                    <template slot="no-options"> Informe a descricao </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="percentual_glp"
                  label-for="percentual_glp"
                  label="% de GLP Derivado do Petróleo"
                >
                  <money
                    class="form-control"
                    v-model="form.percentual_glp"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="percentual_gnn"
                  label-for="percentual_gnn"
                  label="% de Gás Natural Nacional"
                >
                  <money
                    class="form-control"
                    v-model="form.percentual_gnn"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="percentual_gni"
                  label-for="percentual_gni"
                  label="% de Gás Natural Importado"
                >
                  <money
                    class="form-control"
                    v-model="form.percentual_gni"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="valor_parti"
                  label-for="valor_parti"
                  label="Valor da partilha"
                >
                  <money
                    class="form-control"
                    v-model="form.valor_parti"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="glp_qtd_tributavel"
                  label-for="glp_qtd_tributavel"
                  label="Quantidade Tributável"
                >
                  <money
                    class="form-control"
                    v-model="form.glp_qtd_tributavel"
                    v-bind="quantityOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row align-v="center">
              <b-col md="6">
                <b-form-group label="Produto" label-for="name">
                  <VueSelect
                    ref="buscarProduto"
                    :filterable="false"
                    @input="setProduto"
                    :options="produtosFetched"
                    @search="onSearchProduto"
                    v-model="produtoSelected"
                  >
                    <template slot="no-options">
                      Informe o produto pelo nome
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-flex justify-content-between">
                        <div>{{ option.codigo }} - {{ option.descricao }}</div>
                        <span class="text-muted"
                          >QTD: {{ option.quantidade }}</span
                        >
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col :cols="windowWidth > 768 ? 0 : 6">
                <b-form-group label="Quantidade" label-for="quantidade">
                  <money
                    class="form-control"
                    ref="quantidade"
                    id="quantidade"
                    name="quantidade"
                    v-model="quantidade"
                    v-bind="quantityOptions"
                    v-on:keyup.native.enter="handleEnter('preco')"
                  />
                </b-form-group>
              </b-col>
              <b-col :cols="windowWidth > 768 ? 0 : 6">
                <b-form-group label="Unitário" label-for="preco">
                  <money
                    class="form-control"
                    ref="preco"
                    id="preco"
                    name="preco"
                    v-model="preco"
                    v-bind="priceOptions"
                    v-on:keyup.native.enter="addProduto(produtoSelected)"
                  />
                </b-form-group>
              </b-col>
              <b-col :cols="windowWidth > 768 ? 0 : 6">
                <b-form-group label="Total R$" label-for="total">
                  <money
                    disabled
                    class="form-control"
                    id="total"
                    name="total"
                    v-model="total"
                    v-bind="priceOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-for="add">
                  <button @click="addProduto(produtoSelected)">
                    <Plus
                      class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9"
                      v-if="windowWidth > 768"
                    />
                    <button
                      v-else
                      class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2"
                    >
                      Adicionar produto
                    </button>
                  </button>
                </b-form-group>
              </b-col>
            </b-row>

            <TableApp
              item="produto"
              subTitulo=""
              :data="produtos"
              :fields="fields"
              classFilterColumn="nome"
              ref="nome"
              :key="keyToRender"
            >
              <template slot="customRow" scope="props">
                <span
                  slot="item"
                  class="pl-0 pt-8"
                  v-if="props.field == 'item'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ props.rowData.originalIndex + 1 }} /
                      {{ props.rowData.product.codigo }}
                    </div>
                  </div>
                </span>
                <span
                  slot="nome"
                  class="pl-0 pt-8"
                  v-if="props.field == 'descricao'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ props.rowData.product.descricao }}
                      <span
                        v-b-tooltip.hover.right="
                          'Item atendeu a regra de preço de atacado'
                        "
                        v-if="props.rowData.product.tipo_atacado"
                      >
                        <InformationOutline :size="14" />
                      </span>
                    </div>
                  </div>
                </span>
                <span
                  slot="quantidade"
                  class="pl-0 pt-8"
                  v-if="props.field == 'quantidade'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{
                        numberToReal(
                          props.rowData.product.quantidade,
                          "quantidade"
                        )
                      }}
                    </div>
                  </div>
                </span>
                <span
                  slot="preco"
                  class="pl-0 pt-8"
                  v-if="props.field == 'preco'"
                >
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ numberToReal(props.rowData.product.preco, "preco") }}
                    </div>
                  </div>
                </span>

                <span
                  slot="total"
                  v-if="props.field == 'total'"
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  R$
                  {{
                    numberToReal(
                      props.rowData.product.quantidade *
                        props.rowData.product.preco,
                      "preco"
                    )
                  }}
                </span>
                <span slot="actions" v-if="props.field == 'actions'">
                  <div class="row-actions" style="text-align: right !important">
                    <span
                      style="
                        overflow: visible;
                        position: relative;
                        width: 130px;
                      "
                    >
                      <div class="action-buttons">
                        <button
                          @click="
                            openModalProduto(
                              props.rowData,
                              props.rowData.originalIndex
                            )
                          "
                          class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                        >
                          <Pencil :size="16" title="Editar" />
                        </button>
                        <button
                          @click="handleDeleteProduto(props.rowData)"
                          class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                        >
                          <TrashCanIcon :size="16" />
                        </button>
                      </div>
                    </span>
                  </div>
                </span>
              </template>
            </TableApp>
            <b-row v-if="produtos.length > 0">
              <b-col></b-col>
              <b-col></b-col>
              <b-col
                ><span class="text-primary font-weight-bolder"
                  >Total Itens:</span
                >
                {{ numberToReal(totalItensComposicao, "quantidade") }}</b-col
              >
              <b-col
                ><span class="text-primary font-weight-bolder">Total R$:</span
                >{{
                  numberToReal(
                    produtos.reduce(
                      (acc, produto) => acc + produto.totalPrice,
                      0
                    ),
                    "preco"
                  )
                }}</b-col
              >
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Variação 1" label-for="name">
                      <VueSelect
                        ref="buscarVariacao"
                        :filterable="false"
                        :options="variacoesFetched1"
                        :disabled="produto?.id && combinacao.length > 0"
                        v-model="variacaoSelected1"
                        @search="
                          (search, loading) =>
                            onSearchVariacao(search, loading, 1)
                        "
                      >
                        <template slot="no-options">
                          Informe a variação pelo nome
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-flex justify-content-between">
                            <div>
                              {{ option.nome }}
                            </div>
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </VueSelect>
                    </b-form-group>

                    <b-form-group
                      id="variaveis"
                      label-for="variaveis"
                      label="Variáveis"
                      v-if="!!variacaoSelected1"
                    >
                      <span class="text-muted">{{
                        variacaoSelected1.variaveis
                          .map((el) => el.nome)
                          .join(", ")
                      }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="border-top pt-4">
                  <b-col md="6">
                    <b-form-group label="Variação 2" label-for="name">
                      <VueSelect
                        ref="buscarVariacao"
                        :filterable="false"
                        :options="variacoesFetched2"
                        :disabled="produto?.id && combinacao.length > 0"
                        v-model="variacaoSelected2"
                        @search="
                          (search, loading) =>
                            onSearchVariacao(search, loading, 2)
                        "
                      >
                        <template slot="no-options">
                          Informe a variação pelo nome
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-flex justify-content-between">
                            <div>
                              {{ option.nome }}
                            </div>
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </VueSelect>
                    </b-form-group>

                    <b-form-group
                      id="variaveis"
                      label-for="variaveis"
                      label="Variáveis"
                      v-if="!!variacaoSelected2"
                    >
                      <span class="text-muted">{{
                        variacaoSelected2.variaveis
                          .map((el) => el.nome)
                          .join(", ")
                      }}</span>
                      <!-- <div class="d-flex align-items-center mb-3" style="gap: 10px;">
                        <span class="switch switch-icon">
                          <label v-b-tooltip.hover.right="'Não/Sim'">
                            <input type="checkbox" />
                            <span></span>
                          </label>
                        </span>
                        <p class="mb-0">Marcar todos</p>
                      </div>
                      <div class="d-flex align-items-center mb-3" style="gap: 10px;"
                        v-for="(variavel) in variacaoSelected2.variaveis" :key="variavel.nome">
                        <span class="switch switch-icon">
                          <label v-b-tooltip.hover.right="'Não/Sim'">
                            <input type="checkbox" />
                            <span></span>
                          </label>
                        </span>
                        <p class="mb-0">{{ variavel.nome }}</p>
                      </div> -->
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <button
                    ref="mesclar_button"
                    @click="mesclarVariacoes"
                    :disabled="produto?.id && combinacao.length > 0"
                    class="btn btn-secondary border border-primary font-weight-bold"
                  >
                    {{
                      variacaoSelected2 && variacaoSelected1
                        ? "Mesclar"
                        : "Confirmar"
                    }}
                  </button>
                </b-row>
              </b-col>
              <b-col v-if="combinacao.length > 0">
                <h4 class="mb-5">Estoque</h4>
                <div style="max-height: 600px; overflow-y: scroll">
                  <b-row
                    v-for="(item, index) in combinacao"
                    :key="index"
                    class="d-flex align-items-center p-2"
                    :style="{
                      backgroundColor:
                        index % 2 !== 0 ? '#F0f0f0' : 'transparent',
                    }"
                  >
                    <b-col v-for="(key, index2) in item" :key="index2">
                      <p>{{ formatVariacao(key) }}</p>
                    </b-col>
                    <b-col md="2">
                      <b-form-group
                        id="quantidade_variacao"
                        label-for="quantidade_variacao"
                        label="Quantidade"
                      >
                        <money
                          class="form-control"
                          v-model="combinacao[index].quantidade"
                          v-bind="quantityOptions"
                        >
                        </money>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row align-v="center">
              <b-col md="6">
                <b-form-group
                  label="Produto que vai receber a quantidade da compra"
                  label-for="name"
                >
                  <VueSelect
                    ref="buscarProduto"
                    :filterable="false"
                    @input="setProdutoPerca"
                    :options="produtosFetched"
                    @search="onSearchProduto"
                    v-model="produtoSelectedPerca"
                  >
                    <template slot="no-options">
                      Informe o produto pelo nome
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-flex justify-content-between">
                        <div>{{ option.codigo }} - {{ option.descricao }}</div>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="perca_percentual"
                  label-for="perca_percentual"
                  label="Perca %"
                >
                  <money
                    class="form-control"
                    v-model="form.perca_percentual"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <ModalProduto
        :close="closeModalProduto"
        :changeProduto="saveEditProduto"
        :produto="produtoToEdit"
        :open="modalProdutoIsOpen"
        v-if="modalProdutoIsOpen"
      />

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_nova_recorrencia"
              @click="onSubmit"
              class="btn btn-primary font-weight-bold"
            >
              Salvar
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'produtosLista',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-formula"
      title="Fórmula do preço de venda"
      size="md"
      centered
    >
      <label
        >Para calcular o preço de venda dos produtos o Assistente para cálculo
        do preço de venda utiliza a seguinte fórmula:</label
      >
      <b-row class="mt-10" align-h="center" align-v="center">
        <b-col md="4"> Preço de venda = </b-col>
        <b-col md="5">
          <b-row class="border-bottom border-dark mb-2" align-h="center">
            <span class="font-weight-bolder">Custo unitário</span>
          </b-row>
          <b-row align-h="center">
            (100 - (<span class="font-weight-bolder">Outros valores</span>)) /
            100
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="primary" @click="ok()"> OK! </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-assistant"
      title="Assistente de valor de venda"
      size="md"
      centered
    >
      <template #modal-header="{ close }">
        <h5>
          Assistente Preço de Venda
          <button
            class="btn btn-xs btn-primary btn-icon ml-2"
            v-b-tooltip="'Saiba mais sobre a formula aplicada'"
            v-b-modal.modal-formula
          >
            <Quest :size="16" />
          </button>
        </h5>

        <b-button size="sm" variant="outline-primary" @click="close()">
          X
        </b-button>
      </template>
      <div class="mx-auto">
        <b-row>
          <b-col md="6">
            <b-form-group id="custo" label-for="custo" label="Custo R$">
              <money
                class="form-control"
                v-model="form.custo"
                v-bind="priceOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="icm-entrada"
              label-for="icm-entrada"
              label="% ICM de entrada (-)"
            >
              <money
                class="form-control"
                v-model="icmEntrada"
                v-bind="configMoney"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="icm-saida"
              label-for="icm-saida"
              label="% ICM de saída (+)"
            >
              <money
                class="form-control"
                v-model="icmSaida"
                v-bind="configMoney"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="custo-operacional"
              label-for="custo-operacional"
              label="% Custo operacional (+)"
            >
              <money
                class="form-control"
                v-model="custoOperacional"
                v-bind="configMoney"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              id="outros-impostos"
              label-for="outros-impostos"
              label="% Outros impostos (+)"
            >
              <money
                class="form-control"
                v-model="outrosImpostos"
                v-bind="configMoney"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="comissao"
              label-for="comissao"
              label="% Comissão (+)"
            >
              <money
                class="form-control"
                v-model="comissao"
                v-bind="configMoney"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="lucro" label-for="lucro" label="% Lucro (+)">
              <money
                class="form-control"
                v-model="lucro"
                v-bind="configMoney"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col md="8">
            <span class="font-size-lg font-weight-bolder mb-1"
              >Preço de venda sugerido:</span
            >
            <span class="text-primary font-size-h4">
              R$ {{ numberToReal(valorVendaSugerido, 2) }}</span
            >
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button
          variant="primary"
          @click="salvarValorVenda"
          ref="salvarValorVenda"
        >
          Aplicar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import ProdutosData, { ProdutoDataRequired } from "./ProdutosData";
import { Money } from "v-money";
import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { mapGetters } from "vuex";
import Quest from "vue-material-design-icons/AccountQuestion.vue";
import { onboardStore } from "../../../onboardStore";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "@/components/Table/Table3.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import SearchWeb from "vue-material-design-icons/Barcode.vue";
import ModalProduto from "./ModalProduto.vue";

export default {
  name: "app-produtos-form",
  props: {
    url: {
      type: String,
    },
    produto: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Money,
    VueSelect,
    Quest,
    Plus,
    TableApp,
    Pencil,
    TrashCanIcon,
    ModalProduto,
    SearchWeb,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      onboardStore,
      windowWidth: window.innerWidth,
      produtoSelected: null,
      variacoesFetched1: [],
      variacoesFetched2: [],
      combinacao: [],
      tabIndex: 0,
      quantityOptions,
      variacaoSelected1: null,
      variacaoSelected2: null,
      priceOptions,
      urlProdutoImage: null,
      isVariavel: false,
      keyToRender: 0,
      modalProdutoIsOpen: false,
      produtos: [],
      produtoToEdit: null,
      preco: 0,
      total: 0,
      quantidade: 1,
      tipos: [],
      configMoney: settingMoney,
      form: {},
      unidadeMedida: [],
      grupos: [],
      cfops: [],
      isComposto: false,
      cests: [],
      cstcsosns: [],
      tributacoes: [],
      ncms: [],
      tributacao: null,
      cfop: null,
      cest: null,
      ncm: null,
      cstcsosn: null,
      cenqs: [],
      cenq: null,
      cstipis: [],
      cstipi: null,
      cstspis: [],
      cstpis: null,
      fcp: null,
      anps: [],
      anp: null,
      cstscofins: [],
      cstcofins: null,
      produtosFetched: [],
      icmEntrada: 0,
      icmSaida: 0,
      custoOperacional: 0,
      outrosImpostos: 0,
      comissao: 0,
      lucro: 0,
      valorVendaSugerido: 0,
      recalCulateValorVenda: 0,
      fornecedor: null,
      fornecedores: [],
      cfop_nfce: null,
      cfops_nfce: [],
      cstcsosnContribuinte: null,
      tributacaoST: null,
      grupo: null,
      fields: [
        {
          label: "Item/Cód",
          field: "item",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "8%",
        },
        {
          label: "Unitário",
          field: "preco",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Total",
          field: "total",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
      isPerca: false,
      produtoSelectedPerca: null,
      origens: [
        {
          id: 0,
          descricao: "0 - Nacional, exceto as indicadas nos códigos 3 a 5",
        },
        {
          id: 1,
          descricao:
            "1 - Estrangeira - Importação direta, exceto a indicada no código 6",
        },
        {
          id: 2,
          descricao:
            "2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
        },
        {
          id: 3,
          descricao:
            "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%",
        },
        {
          id: 4,
          descricao:
            "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/1967.",
        },
        {
          id: 5,
          descricao:
            "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
        },
        {
          id: 6,
          descricao:
            "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural",
        },
        {
          id: 7,
          descricao:
            "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural",
        },
        {
          id: 8,
          descricao:
            "8 – Nacional: mercadoria ou bem com Conteúdo de Importação superior a 70%;",
        },
      ],
    };
  },
  validations: {
    form: ProdutoDataRequired,
  },
  watch: {
    produto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.isVariavel = newValue?.variavel ?? false;
        this.isPerca = newValue?.perca ?? false;
        if (newValue?.combinacao?.length > 0)
          this.combinacao = newValue.combinacao;
        this.form.estoque_minimo = this.formatNumberToFloat(
          newValue.estoque_minimo
        );
        this.form.preco = this.numberToReal(newValue.preco, "preco");
        this.form.preco_atacado = this.numberToReal(
          newValue.preco_atacado,
          "preco"
        );
        this.form.quantidade = this.numberToReal(
          newValue.quantidade,
          "quantidade"
        );
        this.form.quantidade_atacado = this.numberToReal(
          newValue.quantidade_atacado,
          "quantidade"
        );
        this.form.ipi = this.numberToReal(newValue.ipi, 2);
        this.form.pis = this.numberToReal(newValue.pis, 2);
        this.form.cofins = this.numberToReal(newValue.cofins, 2);
        this.tributacao = newValue.tributacao ?? null;
        this.form.data_ultima_venda = this.formatDate(
          newValue.data_ultima_venda
        );
        this.urlProdutoImage = this.form.imagem;
        this.isComposto = newValue.composto;
        this.produtos = newValue.composicao.map((item, index) => ({
          product: {
            ...item.produto,
            quantidade: item.quantidade,
          },
          active: true,
          currentIndex: index,
          preco: item.produto.preco,
          quantidade: item.quantidade,
          totalPrice:
            this.formatNumberToFloat(item.produto.preco) *
            this.formatNumberToFloat(item.quantidade),
        }));
        this.keyToRender = this.keyToRender + 1;
        this.form.peso = this.formatNumberToFloat(newValue.peso);
        this.cfop = newValue.cfop ?? null;
        this.cest = newValue.cest ?? null;
        this.ncm = newValue.ncm ?? null;
        this.cstcsosn = newValue.cst_csosn ?? null;
        this.cenq = newValue.cenq ?? null;
        this.cstipi = newValue.cst_ipi ?? null;
        this.cstpis = newValue.cst_pis ?? null;
        this.fcp = newValue.fcp ?? null;
        this.anp = newValue.anp ?? null;
        this.cstcofins = newValue.cst_cofins ?? null;
        this.fornecedor = newValue.fornecedor ?? null;
        this.grupo = newValue.grupo ?? null;
        this.cfop_nfce = newValue.cfop_nfce ?? null;
        this.form.data_ultima_compra = this.formatDate(
          newValue.data_ultima_compra
        );
        this.cstcsosnContribuinte = newValue.cst_csosn_contribuinte ?? null;
        this.tributacaoST = newValue.tributacao_s_t ?? null;
        this.produtoSelectedPerca = newValue.perca_produto.produto ?? null;
        this.form.perca_percentual = newValue.perca_produto.perca ?? 0;
      }
    },
    //eslint-disable-next-line no-unused-vars
    "form.custo"(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
    //eslint-disable-next-line no-unused-vars
    icmEntrada(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
    //eslint-disable-next-line no-unused-vars
    icmSaida(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
    //eslint-disable-next-line no-unused-vars
    custoOperacional(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
    //eslint-disable-next-line no-unused-vars
    outrosImpostos(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
    //eslint-disable-next-line no-unused-vars
    comissao(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
    //eslint-disable-next-line no-unused-vars
    lucro(value) {
      this.valorVendaSugerido =
        (this.custoUnitario / ((100 - this.allPercentages) / 100)) *
        (1 + this.lucro / 100);
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
    allPercentages() {
      return (
        this.icmSaida +
        this.custoOperacional +
        this.outrosImpostos +
        this.comissao
      );
    },
    custoUnitario() {
      return this.form.custo - (this.icmEntrada / 100) * this.form.custo;
    },
    totalItensComposicao() {
      if (this.produtos.length > 0) {
        let quantidade = 0;
        this.produtos.forEach((produto, index) => {
          quantidade += parseFloat(produto.product.quantidade);
        });

        return quantidade;
      }

      return 0;
    },
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  methods: {
    async searchCodigoBarra() {
      if (!this.form.cod_barras) {
        this.$toast.warning(
          "Informe o código de barras para realizar a consulta."
        );
        return;
      }

      const { data } = await this.$http.post("produtos/consulta-cod-barra", {
        cod_barras: this.form.cod_barras,
      });

      if (data.Marca) {
        this.form.marca = data.Marca;
      }

      if (data.Nome) {
        this.form.descricao = data.Nome;
      }

      if (data.Ncm) {
        this.setNCM(data.Ncm);
      }

      if (data.Cest_Codigo) {
        this.setCEST(data.Cest_Codigo);
      }

      // const dataFoto = await this.$http.post("produtos/consulta-foto", {
      //   cod_barras: this.form.cod_barras,
      // });
      // var b = Buffer.from(dataFoto.data).toString("base64");
      // const blob = new Blob([dataFoto.data]);
      // var foto = new File([blob], "foto.png", { type: "image/png" });
      // this.form.imagem = foto;
      // console.log(foto);
      // this.urlProdutoImage =
      //   "http://www.eanpictures.com.br:9000/api/gtin/" + this.form.cod_barras;

      this.$toast.success(
        "Produto consultado com sucesso, verifique os dados."
      );
      return;
    },
    formatVariacao(item) {
      if (!isNaN(parseFloat(item)) && isFinite(item)) {
        return;
      }
      return item;
    },
    setAtivo() {
      this.form.ativo = !this.form.ativo;
    },
    selectFiles() {
      document.getElementById("fileUpload").click();
    },
    setProdutoImage(event) {
      this.form.imagem = event.target.files[0];
      this.urlProdutoImage = URL.createObjectURL(this.form.imagem);
    },
    async mesclarVariacoes() {
      this.addLoadingToButton("mesclar_button");
      if (!this.variacaoSelected1 && !this.variacaoSelected2) {
        this.$toast.warning("Selecione alguma variação para prosseguir.");
        return;
      }
      const variaveis = [];
      if (this.variacaoSelected1)
        variaveis.push({ id_variavel: this.variacaoSelected1.id_variavel });
      if (this.variacaoSelected2)
        variaveis.push({ id_variavel: this.variacaoSelected2.id_variavel });

      try {
        const { data } = await this.$http.post("/variavel/mesclar", {
          variaveis,
        });
        this.combinacao = data;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("mesclar_button");
    },
    openModalProduto(produtoData, currentIndex) {
      this.modalProdutoIsOpen = true;
      this.produtoToEdit = {
        ...produtoData.product,
        currentIndex,
        quantidade: this.formatNumberToFloat(produtoData.product.quantidade),
        preco: this.formatNumberToFloat(produtoData.product.preco),
      };
    },

    closeModalProduto() {
      this.modalProdutoIsOpen = false;
    },
    async saveEditProduto(newEditedProduto) {
      const aux = _.cloneDeep(this.produtos);
      aux.splice(newEditedProduto.currentIndex, 1, {
        ...this.produtos[newEditedProduto.currentIndex],
        product: newEditedProduto,
        preco: newEditedProduto.preco,
        quantidade: newEditedProduto.quantidade,
        totalPrice: newEditedProduto.preco * newEditedProduto.quantidade,
      });
      this.produtos = aux;
    },
    handleEnter(ref) {
      if (this.fromSelect) {
        this.fromSelect = false;
        return;
      }
      if (!this.$refs[ref]) return;
      this.$refs[ref].$el.focus();
    },
    handleDeleteProduto(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o produto " + item.product.descricao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const aux = this.produtos
              .filter((produto, index) => index !== item.originalIndex)
              .map((item, index) => ({
                ...item,
                product: { ...item.product, currentIndex: index },
              }));
            this.produtos = _.cloneDeep(aux);
          }
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    setProduto(produto) {
      if (!produto) return;
      this.descricao = produto.descricao;
      this.estoqueProduto = produto.quantidade;
      const preco_atacado = produto?.preco_atacado ?? 0;
      const qtd_atacado = produto?.quantidade_atacado ?? 0;
      this.quantidade = 1;

      const isAtacadoValue =
        this.quantidade >= qtd_atacado && preco_atacado > 0;

      if (isAtacadoValue) {
        this.preco = parseFloat(preco_atacado);
      } else this.preco = parseFloat(produto.preco);

      this.total = this.quantidade * parseFloat(this.preco);
      this.produtoSelected = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        tipo_atacado: isAtacadoValue,
      };
      this.produtoSelected.total = this.total;

      this.produtoFilled = true;
      this.fromSelect = true;
      this.$nextTick(() => {
        this.$refs.quantidade.$el.focus();
      });
    },
    async addProduto(produto) {
      if (_.isEmpty(produto)) return;
      let produtoJaAdd = false;

      if (this.produtos.length > 0) {
        this.produtos.forEach((product, index) => {
          if (product.product.id === produto.id) {
            this.$toast.warning(
              "O item: " +
                produto.descricao +
                " já foi adicionado na composição, verifique"
            );
            produtoJaAdd = true;
          }
        });
      }

      if (produtoJaAdd) return;

      const produtoFormatted = {
        ...produto,
        preco: this.preco,
        quantidade: parseFloat(this.quantidade),
        total: this.preco * this.quantidade,
      };
      this.produtos = [
        ...this.produtos,
        {
          product: produtoFormatted,
          active: true,
          currentIndex: this.produtos.length,
          totalPrice: this.preco * this.quantidade,
          quantidade: parseFloat(this.quantidade),
        },
      ];

      this.$nextTick(() => {
        this.$refs.buscarProduto.$refs.search.focus();
      });
      this.produtoSelected = null;
      this.resetInputs();
    },
    resetInputs() {
      this.quantidade = 1;
      this.preco = 0;
      this.total = 0;
    },
    onSearchProduto(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      vm.produtosFetched = await vm.fetchProdutos(search, vm.typeFilter);
      loading(false);
    }, 350),

    onSearchVariacao(search, loading, type) {
      if (search.length > 2) {
        loading(true);
        this.searchVariacao(loading, search, this, type);
      }
    },
    searchVariacao: _.debounce(async (loading, search, vm, type) => {
      const { data } = await vm.$http.post("/variavel/get-variaveis", {
        nome: search,
      });
      if (type === 1) vm.variacoesFetched1 = [...data];
      if (type === 2) vm.variacoesFetched2 = [...data];
      loading(false);
    }, 350),
    setComposto() {
      this.isComposto = !this.isComposto;
    },
    salvarValorVenda() {
      this.form.preco = this.valorVendaSugerido;
      this.$bvModal.hide("modal-assistant");
      this.$toast.success("Valor sugerido aplicado com sucesso");
    },
    setPesado() {
      this.form.pesado = this.form.pesado == true ? false : true;
    },
    async getDataInit() {
      this.form = new ProdutosData({});
      this.getUnidadeMedidas().then((data) => (this.unidadeMedida = data));
      this.getCstCsosn().then((data) => (this.cstcsosns = data));
      this.getTributacao().then((data) => (this.tributacoes = data));
      this.getCenqByCodigo().then((data) => (this.cenqs = data));
      this.getCSTIPIByCodigo().then((data) => (this.cstipis = data));
      this.getCSTPISByCodigo().then((data) => (this.cstspis = data));
      this.getAnpByCodigo().then((data) => (this.anps = data));
      this.getCSTCofinsByCodigo().then((data) => (this.cstscofins = data));
      if (this.produto.id) {
        this.form = { ...this.produto };
        this.isComposto = this.produto.composto;
        this.isPerca = this.produto.perca;
        this.produtos = this.produto.composicao;
        this.produtoSelectedPerca = this.produto.perca_produto.produto;
        this.setCFOP(this.produto.cfop);
        this.setCEST(this.produto.cest);
        this.setNCM(this.produto.ncm);
        this.setCSTCSOSN(this.produto.cst_csosn);
        this.setTributacao(this.produto.tributacao);
        this.setCSTIPI(this.produto.cst_ipi);
        this.setCSTPIS(this.produto.cst_pis);
        this.setCENQ(this.produto.cenq);
        this.setFCP(this.produto.fcp);
        this.setANP(this.produto.anp);
        this.setCSTCofins(this.produto.cst_cofins);
        this.setFornecedor(this.produto.fornecedor);
        this.setCFOPNFce(this.produto.cfop_nfce);
        this.setCSTCSOSNContribuinte(this.produto.cst_csosn_contribuinte);
        this.setTributacaoST(this.produto.tributacao_s_t);
        this.setGrupo(this.produto.grupo);
      }
      try {
        const { data } = await this.$http.post("/produtos/tipos");
        this.tipos = data;
        this.form.id_tipo = 1;
      } catch (error) {
        console.log(error);
      }
    },
    setCFOPNFce(cfopnfce) {
      this.cfop_nfce = cfopnfce;
    },
    setFornecedor(fornecedor) {
      this.fornecedor = fornecedor;
    },
    setGrupo(grupo) {
      this.grupo = grupo;
    },
    setANP(anp) {
      this.anp = anp;
    },
    setFCP(fcp) {
      this.fcp = fcp;
    },
    setCSTPIS(cstpis) {
      this.cstpis = cstpis;
    },
    setCSTCofins(cstcofins) {
      this.cstcofins = cstcofins;
    },
    setCSTIPI(cstipi) {
      this.cstipi = cstipi;
    },
    setCENQ(cenq) {
      this.cenq = cenq;
    },
    setCFOP(cfop) {
      this.cfop = cfop;
    },
    setCEST(cest) {
      this.cest = cest;
    },
    setNCM(ncm) {
      this.ncm = ncm;
    },
    setCSTCSOSN(cstcsosn) {
      this.cstcsosn = cstcsosn;
    },
    setTributacao(tributacao) {
      this.tributacao = tributacao;
    },
    setTributacaoST(tributacao) {
      this.tributacaoST = tributacao;
    },
    setCSTCSOSNContribuinte(cstcsosn) {
      this.cstcsosnContribuinte = cstcsosn;
    },
    setProdutoPerca(produto) {
      this.produtoSelectedPerca = produto;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    async onSubmit() {
      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (this.form.id_medida == null) {
          this.$toast.warning("Selecione uma unidade de medida!");
          return;
        }
        this.addLoadingToButton("submit_nova_recorrencia");

        let produto = new FormData();

        if (this.cfop != null) {
          this.form.id_cfop = this.cfop.id_cfop;
        }
        if (this.cfop == null) {
          this.form.id_cfop = null;
        }
        if (this.cest != null) {
          this.form.id_cest = this.cest.id_cest;
        }
        if (this.cest == null) {
          this.form.id_cest = null;
        }
        if (this.ncm != null) {
          this.form.id_ncm = this.ncm.id_ncm;
        }
        if (this.ncm == null) {
          this.form.id_ncm = null;
        }
        if (this.cstcsosn != null) {
          this.form.id_cst_csosn = this.cstcsosn.id_cst_csosn;
        }
        if (this.cstcsosn == null) {
          this.form.id_cst_csosn = null;
        }
        if (this.tributacao != null) {
          this.form.id_tributacao = this.tributacao.id;
        }
        if (this.tributacao == null) {
          this.form.id_tributacao = null;
        }
        if (this.cenq != null) {
          this.form.id_cenq = this.cenq.id_cenq;
        }
        if (this.cenq == null) {
          this.form.id_cenq = null;
        }
        if (this.cstipi != null) {
          this.form.id_cstipi = this.cstipi.id_cstipi;
        }
        if (this.cstipi == null) {
          this.form.id_cstipi = null;
        }
        if (this.cstpis != null) {
          this.form.id_cstpis = this.cstpis.id_cstpis;
        }
        if (this.cstpis == null) {
          this.form.id_cstpis = null;
        }
        if (this.fcp != null) {
          this.form.id_fcp = this.fcp.id;
        }
        if (this.fcp == null) {
          this.form.id_fcp = null;
        }
        if (this.anp != null) {
          this.form.id_anp = this.anp.id_anp;
        }
        if (this.anp == null) {
          this.form.id_anp = null;
        }
        if (this.cstcofins != null) {
          this.form.id_cstcofins = this.cstcofins.id_cstcofins;
        }
        if (this.cstcofins == null) {
          this.form.id_cstcofins = null;
        }
        if (this.cstcofins == null) {
          this.form.id_cstcofins = null;
        }
        if (this.fornecedor != null) {
          this.form.id_pessoa = this.fornecedor.id_pessoa;
        }
        if (this.fornecedor == null) {
          this.form.id_pessoa = null;
        }
        if (this.grupo != null) {
          this.form.id_grupo = this.grupo.id;
        }
        if (this.grupo == null) {
          this.form.id_grupo = null;
        }

        if (this.cfop_nfce != null) {
          this.form.id_cfop_nfce = this.cfop_nfce.id_cfop;
        }

        if (this.cfop_nfce == null) {
          this.form.id_cfop_nfce = null;
        }

        if (this.cstcsosnContribuinte != null) {
          this.form.id_cst_csosn_contribuinte =
            this.cstcsosnContribuinte.id_cst_csosn;
        }

        if (this.cstcsosnContribuinte == null) {
          this.form.id_cst_csosn_contribuinte = null;
        }

        if (this.tributacaoST != null) {
          this.form.id_tributacao_st = this.tributacaoST.id;
        }

        if (this.tributacaoST == null) {
          this.form.id_tributacao_st = null;
        }

        if (this.isComposto) {
          this.form.composto = true;
        }

        if (this.isVariavel) {
          this.form.variavel = true;
        }

        if (this.isPerca) {
          this.form.perca = true;
        }

        const composicao = this.produtos.map((produto) => ({
          id_produto: produto.product.id,
          quantidade: produto.product.quantidade,
        }));
        const ids_variaveis = [];
        if (this.variacaoSelected1)
          ids_variaveis.push({
            id_variavel: this.variacaoSelected1.id_variavel,
          });
        if (this.variacaoSelected2)
          ids_variaveis.push({
            id_variavel: this.variacaoSelected2.id_variavel,
          });

        for (const key in this.form) {
          // POPULATING THE PRODUTO DATA
          if (key === "data_ultima_venda" || key === "data_ultima_compra") {
            // produto.append(`${key}`, !this.form[key] ? null : this.form[key]);
          } else if (key === "unidade_medida") {
            produto.append(`${key}`, JSON.stringify(this.form[key]));
          } else produto.append(`${key}`, this.form[key]);
        }

        produto.append("ids_variaveis", JSON.stringify(ids_variaveis));
        produto.append("composicao", JSON.stringify(composicao));
        produto.append("combinacao", JSON.stringify(this.combinacao));

        if (this.produtoSelectedPerca) {
          if (this.form.perca_percentual) {
            produto.append("perca_percentual", this.form.perca_percentual);
          }

          produto.append(
            "produto_perca",
            JSON.stringify(this.produtoSelectedPerca)
          );
        }

        await this.$http.post(this.url, produto);

        if (this.onboardStore.currentStep > 0) {
          this.onboardStore.currentStep = 7;
        }

        this.urlProdutoImage = null;
        this.form.imagem = null;
        this.$router.push({ name: "produtosLista" });
      } catch {
        this.removeLoadingToButton("submit_nova_recorrencia");
      }
    },
    onSearchCFOPNfce(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOPNFCe(loading, search, this);
      }
    },
    searchCFOPNFCe: _.debounce(async (loading, search, vm) => {
      vm.cfops_nfce = await vm.getCFOPByCodigo(search);
      loading(false);
    }, 350),

    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    searchCFOP: _.debounce(async (loading, search, vm) => {
      vm.cfops = await vm.getCFOPByCodigo(search);
      loading(false);
    }, 350),

    onSearchCEST(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCEST(loading, search, this);
      }
    },
    searchCEST: _.debounce(async (loading, search, vm) => {
      vm.cests = await vm.getCstCest(search);
      loading(false);
    }, 350),

    onSearchNCM(search, loading) {
      if (search.length) {
        loading(true);
        this.searchNCM(loading, search, this);
      }
    },
    searchNCM: _.debounce(async (loading, search, vm) => {
      vm.ncms = await vm.getNCM(search);
      loading(false);
    }, 350),

    onSearchCENQ(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCENQ(loading, search, this);
      }
    },
    searchCENQ: _.debounce(async (loading, search, vm) => {
      vm.cenqs = await vm.getCenqByCodigo(search);
      loading(false);
    }, 350),
    onSearchCSTIPI(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCSTIPI(loading, search, this);
      }
    },
    searchCSTIPI: _.debounce(async (loading, search, vm) => {
      vm.cstipis = await vm.getCSTIPIByCodigo(search);
      loading(false);
    }, 350),
    onSearchANP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchANP(loading, search, this);
      }
    },
    searchANP: _.debounce(async (loading, search, vm) => {
      vm.anps = await vm.getAnpByCodigo(search);
      loading(false);
    }, 350),

    onSearchFornecedor(search, loading) {
      if (search.length) {
        loading(true);
        this.searchFornecedor(loading, search, this);
      }
    },
    searchFornecedor: _.debounce(async (loading, search, vm) => {
      vm.fornecedores = await vm.getFornecedorByNome(search);
      loading(false);
    }, 350),
    onSearchGrupo(search, loading) {
      if (search.length) {
        loading(true);
        this.searchGrupo(loading, search, this);
      }
    },
    searchGrupo: _.debounce(async (loading, search, vm) => {
      vm.grupos = await vm.getGrupoByDescricao(search);
      loading(false);
    }, 350),
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
